import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import Requests from "./plugins/request";
import VueMeta from "vue-meta";
import "@/assets/scss/_table.scss";
import "flag-icon-css/css/flag-icon.min.css";

Vue.use(VueMeta);
Vue.use(Requests, {
  productionURL: "https://pay.epin.com.tr",
  sandboxURL: "https://sandbox-pay.epin.com.tr",
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
