<template>
  <v-app>
    <v-app-bar v-if="!isLoginPage" app color="white lighten-1" clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ `PAYMAPI ${$t("app")}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" v-bind="attrs" v-on="on" icon large>
            <v-icon>
              {{ `flag-icon flag-icon-${locale.icon}` }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in locales"
            :key="index"
            @click="setLocale($event, item)"
          >
            <v-list-item-icon>
              <v-icon color="primary">
                {{ `flag-icon flag-icon-${item.icon} flag-icon-round` }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              item.value === "tr" ? "Türkçe" : "English"
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-if="!isLoginPage"
      v-model="drawer"
      app
      clipped
      color="primary"
      bottom
      dark
    >
      <v-list class="py-4">
        <v-list-item>
          <v-list-item-avatar color="secondary">
            <v-icon color="primary">
              mdi-account
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ _currentUser.username }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn icon v-bind="attrs" v-on="on" to="/login">
                  <v-icon>mdi-power</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("pages.login.sign_out") }}
              </span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list dense nav>
        <v-list-item to="/" exact-active-class="transparent">
          <v-list-item-icon>
            <v-icon>
              mdi-calendar-blank
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="white--text">
            {{ $t("drawer.payment_transactions") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings">
          <v-list-item-icon>
            <v-icon>
              mdi-cog
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="white--text">
            {{ $t("drawer.api_settings") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="openDocumentation">
          <v-list-item-icon>
            <v-icon>
              mdi-book-open-blank-variant
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="white--text">
            {{ $t("drawer.api_documentation") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view :locales="locales" @update-locale="setLocale" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    locale: JSON.parse(localStorage.getItem("locale"))
      ? JSON.parse(localStorage.getItem("locale"))
      : { value: "en", icon: "us" },
    locales: [
      { value: "en", icon: "us" },
      { value: "tr", icon: "tr" },
    ],
    isLoginPage: true,
    drawer: true,
  }),

  created() {
    const locale = JSON.parse(localStorage.getItem("locale"));
    locale ? this.setLocale(null, locale) : null;
  },

  computed: {
    _currentUser: function() {
      return JSON.parse(localStorage.getItem("currentUser"));
    },
  },

  watch: {
    "$route.name": function(routeName) {
      routeName === "Login"
        ? (this.isLoginPage = true)
        : (this.isLoginPage = false);
    },
    locale: function(n) {
      localStorage.setItem("locale", JSON.stringify(n));
    },
  },

  methods: {
    setLocale(e, item) {
      this.$i18n.locale = item.value;
      this.locale = item;
    },
    openDocumentation() {
      window.open("https://developer-pay.epin.com.tr");
    },
  },
};
</script>

<style lang="scss">
.v-list--nav {
  .v-list-item {
    &--active {
      color: transparent !important;
      .v-list-item__icon,
      .v-list-item__title {
        color: #f2a932 !important;
      }
    }
  }
}
.transparent {
  background-color: black !important;
}
</style>
