export default {
  install(Vue, options) {
    Vue.mixin({
      data: () => ({
        currentUser: JSON.parse(localStorage.getItem("currentUser")),
        loginError: false,
        tableLoading: false,
        responseErr: {
          isVisible: false,
          text: null,
          code: null,
        },
        baseURL: null,

        // for pagination
      }),
      created() {
        this.baseURL = options.sandboxURL;
      },
      methods: {
        async makeReq({
          method,
          url,
          data = null,
          returnAll = false,
          showErr = true,
        }) {
          this.tableLoading = true;
          let headers = {};
          if (this.currentUser) {
            headers.Token = this.currentUser.token;
            headers.User = this.currentUser.username;
          }
          let ctx = {
            method,
            headers: headers,
          };
          if (method !== "GET") ctx.body = JSON.stringify(data);

          try {
            const response = await fetch(`${this.baseURL}/${url}`, ctx);
            const data = await response.json();
            if (data.statusCode === 100) {
              /* if (data.data.token) {
                this.currentUser = { ...data.data };
                localStorage.setItem("currentUser", JSON.stringify(data.data));
                this.setToken(data.data.token);
              } */

              this.tableLoading = false;
              return returnAll ? data : data.data;
            }
            if (data.statusCode === 401) {
              if (this.$route.name === "Login") {
                this.loginError = true;
              } else {
                await this.$router.push({ name: "Login" });
              }
            }
            // error case
            if (data.statusCode !== 100 && data.statusCode !== 401) {
              showErr
                ? (this.responseErr = Object.assign(
                    {},
                    {
                      isVisible: true,
                      text: data.statusMsg,
                      code: data.statusCode,
                    }
                  ))
                : null;
              this.tableLoading = false;

              return { ...data, data: [] };
            }
          } catch (error) {
            console.log({ error });
          }
        },
        setToken(token) {
          if (token) {
            this.currentUser.token = token;
          } else {
            localStorage.removeItem("currentUser");
            this.$router.push({ name: "Login" });
          }
        },
      },
    });
  },
};
